import {
  legacy_createStore as createStore, combineReducers,
  // applyMiddleware,
  // compose
} from 'redux';
import reducers from './reducers';

const rootReducer = combineReducers(reducers);
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  // composeEnhancers(applyMiddleware())
);

export default store;
