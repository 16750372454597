import { Suspense, lazy } from 'react'
import { ApolloProvider } from '@apollo/client'
import '@preact/signals-react/auto'
import { Provider as MapProvider } from 'react-redux'
import client from './ApolloClientSetup'
import Navbar from './components/Navbar/Navbar'
import store from './state/store'
import Spinner from './components/Spinner/Spinner'

const MapWrapper = lazy(() => import('./components/Map/MapWrapper'))

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Navbar />
        <MapProvider store={store}>
          <Suspense fallback={<Spinner size="l" message="Loading map..." />}>
            <MapWrapper />
          </Suspense>
        </MapProvider>
      </div>
    </ApolloProvider>
  )
}

export default App
