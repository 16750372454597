import PropTypes from 'prop-types'

const Spinner = ({ size, percentage, message }) => {
  return (
    <div className="spinner-container">
      <div className={`spinner spinner--${size}`} />
      {percentage != null ? <p className="spinner__percentage">{percentage}%</p> : null}
      {message && <p className="spinner__message">{message}</p>}
    </div>
  )
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']).isRequired,
  percentage: PropTypes.number,
  message: PropTypes.string,
}

export default Spinner
