import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Operation: ${operation.operationName}, Path: ${path.join(' -> ')}`,
        { locations }
      )
    );
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError.message}`, {
      endpoint: operation.getContext().response.url,
      operation: operation.operationName,
      networkErrorDetails: networkError
    });

  }
});

const client = new ApolloClient({
  link: errorLink.concat(
    new HttpLink({
      uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
      credentials: 'include'
    })
  ),
  cache,
});

export default client;
