import { Suspense, lazy } from 'react'
import { useSignal } from '@preact/signals-react/runtime'
import '../Spinner/Spinner'
import Spinner from '../Spinner/Spinner'

const Drawer = lazy(() => import('../Drawer/Drawer'))

const Navbar = () => {
  const isActive = useSignal(false)
  const activeDrawer = useSignal('')

  const toggleActiveClass = () => {
    isActive.value = !isActive.value
  }

  const toggleDrawer = drawerId => () => {
    activeDrawer.value = activeDrawer.value === drawerId ? '' : drawerId
  }

  return (
    <>
      <header className="App-header">
        <nav className="navbar">
          <div className="navbar__title-container">
            <h1 className="navbar__title">What&apos;s renovated in your neighborhood?</h1>
            <p className="navbar__subtitle">An interactive platform to see renovations and improvements to multi-unit housing in Toronto</p>
          </div>
          <div className="navbar__menu-container">
            <ul className={`navbar__menu ${isActive.value ? 'navbar__menu--active' : ''}`}>
              <li className="navbar__item">
                <button onClick={toggleDrawer('about')}>About the Project</button>
              </li>
              <li className="navbar__item">
                <button onClick={toggleDrawer('how-to-use')}>How To Use</button>
              </li>
            </ul>
          </div>
          <div className={`navbar__hamburger ${isActive.value ? 'navbar__hamburger--active' : ''}`} onClick={toggleActiveClass}>
            <span className="navbar__bar"></span>
            <span className="navbar__bar"></span>
            <span className="navbar__bar"></span>
          </div>
        </nav>
      </header>
      <Suspense
        fallback={
          <div className="spinner-overlay">
            <Spinner size="m" />
          </div>
        }
      >
        <Drawer
          isOpen={activeDrawer.value === 'about'}
          toggleDrawer={toggleDrawer('about')}
          contentId="about"
          customIdSuffix="drawer-about"
          className="drawer drawer--about"
          zIndex="1005"
          size={460}
          duration={200}
          direction="right"
        />
        <Drawer
          isOpen={activeDrawer.value === 'how-to-use'}
          toggleDrawer={toggleDrawer('howToUse')}
          contentId="how-to-use" // must match the file name of the markdown file in the public folder
          direction="right"
          className="drawer drawer--how-to-use"
          zIndex="1005"
          size={550}
          duration={200}
          lockBackgroundScroll={false}
        />
      </Suspense>
    </>
  )
}

export default Navbar
