const initialState = {
  markers: [],
  initialMarkers: [],
  selectedStatuses: [],
  bounds: null,
  work: '',
  issuedYear: '',
  center: { lat: 43.6532, lng: -79.3832 },
  filtersReady: false,
  fetchMarkers: true,
  highlightPermitId: null,
  zoom: 14,
};
// @TODO: some of these will go away once we adapt to RTK
export const actionTypes = {
  SET_MARKERS: 'SET_MARKERS',
  SET_INITIAL_MARKERS: 'SET_INITIAL_MARKERS',
  SET_FILTERS_READY: 'SET_FILTERS_READY',
  SET_FETCH_MARKERS: 'SET_FETCH_MARKERS',
  SET_SELECTED_STATUSES: 'SET_SELECTED_STATUSES',
  SET_BOUNDS: 'SET_BOUNDS',
  SET_WORK: 'SET_WORK',
  SET_ISSUED_YEAR: 'SET_ISSUED_YEAR',
  SET_CENTER: 'SET_CENTER',
  SET_HIGHLIGHT_PERMIT_ID: 'SET_HIGHLIGHT_PERMIT_ID',
  SET_ZOOM: 'SET_ZOOM',
};

const countStatuses = (markers) => {
  return markers.reduce((acc, marker) => {
    acc[marker.status] = (acc[marker.status] || 0) + 1;
    return acc;
  }, {});
};

const mapReducer = (state = initialState, action) => {
  // console.log('Dispatching action:', action);
  switch (action.type) {
    case actionTypes.SET_MARKERS: {
      const statusCounts = countStatuses(action.payload);
      return {
        ...state,
        markers: action.payload,
        markerCount: action.payload.length,
        statusCounts: statusCounts
      };
    }
    case actionTypes.SET_INITIAL_MARKERS: {
      return { ...state, initialMarkers: action.payload };
    }
    case actionTypes.SET_FETCH_MARKERS: {
      return { ...state, fetchMarkers: action.payload };
    }
    case actionTypes.SET_FILTERS_READY:
      return { ...state, filtersReady: action.payload };
    case actionTypes.SET_SELECTED_STATUSES: {
      return { ...state, selectedStatuses: action.payload };
    }
    case actionTypes.SET_BOUNDS: {
      return { ...state, bounds: action.payload };
    }
    case actionTypes.SET_WORK: {
      return { ...state, work: action.payload };
    }
    case actionTypes.SET_ISSUED_YEAR: {
      return { ...state, issuedYear: action.payload };
    }
    case actionTypes.SET_CENTER: {
      return { ...state, center: action.payload };
    }
    case actionTypes.SET_HIGHLIGHT_PERMIT_ID: {
      return { ...state, highlightPermitId: action.payload };
    }
    case actionTypes.SET_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    default:
      return state;
  }
};

export default mapReducer;
